// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
// COMPONENTS
import { Link } from "../components/link"

// COMPONENT FUNCTION
export const RentalsBlurb = () => {
  // COMPONENT
  return (
    <Typography paragraph>
      Bay Sails Marine rents boats in season (June 1 - October 1) by the week,
      month, or season to qualified boaters. We furnish bare boats and renters
      assume the responsibility of supplying all safety gear. Boats available
      include Sunfish, Rhodes 19, and Alcar powerboats. Above pictures are
      examples only. Please <Link to="/contact">contact us</Link> for a rental
      agreement.
    </Typography>
  )
}
