// REACT
import React from "react"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { RentalsBlurb } from "../components/rentalsBlurb"

// COMPONENT FUNCTION
const RentalsRhodes19Page = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      rentalsRhodes19: file(relativePath: { eq: "rentals-rhodes-19.jpg" }) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Rentals - Rhodes 19">
      <PageTitle title="RHODES 19 RENTAL" />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.rentalsRhodes19.childImageSharp.fluid}
          caption="Rhodes 19 Sailing"
          displayCaption
        />
      </ImageBlock>

      <RentalsBlurb />
    </Layout>
  )
}

export default RentalsRhodes19Page
